import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  Alert,
  InputGroup,
  Spinner,
} from 'reactstrap';
import { deleteField } from 'firebase/firestore';
import Select from '@availity/select';
import { Form, Input } from '@availity/form';
import { FieldArray } from 'formik';
import FormErrorMessage from '../../Common/FormErrorMessage';
import FileUpload from '../../Common/FileUpload';
import HTMLEditor from '../../Common/HTMLEditor';
import CenterBox from '../../Common/CenterBox';
import { Dots } from 'react-activity';
import formValidation from './formValidation';

import * as articleContentsActions from '../../../store/articles/contents/actions';
import { useDispatch, useSelector } from 'react-redux';
import { translatorLanguages } from '../../../selectors/auth';
import { selectArticleContent } from '../../../selectors/article';
import { SubmitButton } from '../../Common/Modal/modalActionButtons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { isEmptyObj } from '../../../helpers/sharedFunction';
import colors from '../../CommonStyle/colors';

const STORAGE_BASE_PATH = 'articles';
const BUTTON_TIMELINES = [
  {
    label: 'None',
    value: null,
  },
  {
    label: 'Timeline Sinistra Gravidanza',
    value: 'sx-timeline',
  },
  {
    label: 'Timeline Destra Gravidanza',
    value: 'dx-timeline-gravidanza',
  },
];

const functions = getFunctions();
const copyTranslateArticle = httpsCallable(functions, 'copyTranslateArticle');

let sunEditor = null;
const sunEditorRef = (editor) => {
  sunEditor = editor;
};

const FormArticleContentContent = ({ article, lang, closeModal }) => {
  const articleContentId = `${article.id}-${lang}`;
  const loading = useSelector((state) => state.ArticleContent.loading);

  const articleContent = useSelector(selectArticleContent(articleContentId));
  const create = !articleContent;

  const enabledLanguages = useSelector(translatorLanguages);
  const canEdit = enabledLanguages.includes(lang);

  const [loadingTranslation, setLoadingTranslation] = useState(false);
  const [errorTranslation, setErrorTranslation] = useState('');

  const [files, setFiles] = useState(null);
  const [errorFile, setErrorFile] = useState('');
  const [content, setContent] = useState(articleContent?.content || '');
  const [buttonTimelineImage, setButtonTimelineImage] = useState(
    articleContent?.buttonTimelineImage,
  );

  const handleFiles = (name, files) => {
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(isEmptyObj(newFiles) ? null : newFiles);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(articleContentsActions.fetchArticleContent(articleContentId));
  }, []);

  const handleSubmit = (values) => {
    const { published, ballonTags, buttonTimeline, buttonTimelineTitle } =
      values;
    if (
      buttonTimeline &&
      !articleContent?.buttonTimelineImage &&
      !buttonTimelineImage &&
      !files?.buttonTimelineImage
    )
      return setErrorFile('Insert button timeline image, please!');

    delete values.buttonTimeline;
    delete values.buttonTimelineTitle;
    delete values.buttonTimelineImage;

    dispatch(
      create
        ? articleContentsActions.createArticleContent(
            {
              id: articleContentId,
              ...values,
              files,
              articleId: article.id,
              lang,
              content,
              published: published || false,
              ...(buttonTimeline && { buttonTimeline, buttonTimelineTitle }),
              ...(buttonTimelineImage && { buttonTimelineImage }),
            },
            article,
          )
        : articleContentsActions.updateArticleContent({
            id: articleContentId,
            ...values,
            ...(files && { files }),
            content,
            published: values.published || false,
            ...(Array.isArray(ballonTags) &&
              !ballonTags.length && { ballonTags: deleteField() }),
            ...(buttonTimeline
              ? { buttonTimeline, buttonTimelineTitle }
              : {
                  buttonTimeline: deleteField(),
                  buttonTimelineTitle: deleteField(),
                  buttonTimelineImage: deleteField(),
                }),
            ...(buttonTimelineImage && { buttonTimelineImage }),
          }),
    );
    closeModal();
  };

  const handleContentChange = (contents) => {
    setContent(contents);
  };

  const handleTranslate = useCallback(
    (setFieldValue) => {
      setErrorTranslation(null);
      setLoadingTranslation(true);

      copyTranslateArticle({
        articleContentId: `${article.id}-IT`,
        targetLang: lang,
      })
        .then((result) => {
          const data = result.data;
          const {
            error,
            title,
            content,
            ballonTags,
            buttonTimeline,
            buttonTimelineImage,
            buttonTimelineTitle,
          } = data;
          if (error) throw new Error(error.message);

          title && setFieldValue('title', title);
          content && sunEditor && sunEditor.setContents(content);
          buttonTimelineTitle &&
            setFieldValue('buttonTimelineTitle', buttonTimelineTitle);
          buttonTimeline && setFieldValue('buttonTimeline', buttonTimeline);
          buttonTimelineImage && setButtonTimelineImage(buttonTimelineImage);

          if (Array.isArray(ballonTags)) {
            ballonTags.forEach((tag, index) => {
              setFieldValue(`ballonTags.${index}.order`, tag.order);
              setFieldValue(`ballonTags.${index}.title`, tag.title);
            });
          }
        })
        .catch((error) => {
          setErrorTranslation(error.message);
          console.error(error);
        })
        .finally(() => {
          setLoadingTranslation(false);
        });
    },
    [article, lang],
  );

  return (
    <Card>
      <CardBody>
        {loading ? (
          <CenterBox height={500}>
            <Dots color={colors.info} size={30} />
          </CenterBox>
        ) : (
          <Form
            initialValues={articleContent || {}}
            validationSchema={formValidation}
            onSubmit={handleSubmit}>
            {({ isValid, values, setFieldValue }) => (
              <>
                <Collapse isOpen={!!(errorFile || errorTranslation)}>
                  <>
                    {errorFile && <Alert color="danger">{errorFile}</Alert>}
                    {errorTranslation && (
                      <Alert color="danger">{errorTranslation}</Alert>
                    )}
                  </>
                </Collapse>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <Input type="text" name="title" id="title" />
                      <FormErrorMessage name="title" />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <div
                        className="custom-control custom-switch mb-2"
                        dir="ltr">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="published"
                          name="published"
                          checked={!!values.published}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="published">
                          Published?
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  {articleContent?.wpPostId && (
                    <Col md="2">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-secondary"
                        onClick={() =>
                          window.open(
                            `https://www.mammacheapp.com/wp-admin/post.php?post=${articleContent?.wpPostId}&action=edit`,
                            '_blank',
                          )
                        }
                        disabled={loading}>
                        <i className="bx bxl-wordpress mr-1"></i>
                        Open old CMS
                      </button>
                    </Col>
                  )}
                  {articleContent?.lang !== 'IT' && (
                    <Col md="2" className="d-inline-block my-auto">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success"
                        onClick={() => handleTranslate(setFieldValue)}
                        disabled={loading || loadingTranslation}>
                        <i className="bx bx-copy-alt mr-1"></i>
                        Copy from IT
                      </button>
                      <Spinner
                        size="sm"
                        color="primary"
                        hidden={!loadingTranslation}
                        className="ml-2"
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md="12">
                    <FieldArray
                      name="ballonTags"
                      render={(arrayHelpers) => {
                        const tags = values.ballonTags;
                        return (
                          <FormGroup>
                            <Label htmlFor="ballonTags">Ballon Tags</Label>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline btn-light ml-4"
                              onClick={() =>
                                arrayHelpers.push({
                                  order: 1,
                                  name: '',
                                })
                              }>
                              <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                              Add
                            </button>
                            {Array.isArray(tags) &&
                              tags.map((tag, index) => (
                                <Row key={index} className="mb-2">
                                  <Col md="2">
                                    <FormGroup>
                                      <Label
                                        htmlFor={`ballonTags.${index}.order`}>
                                        Order
                                      </Label>
                                      <InputGroup>
                                        <div className="input-group-append">
                                          <button
                                            className="btn btn-danger p-0"
                                            style={{ width: '31px' }}
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }>
                                            <i className="bx bx-trash d-block font-size-15" />
                                          </button>
                                        </div>
                                        <Input
                                          type="number"
                                          step="1"
                                          min={1}
                                          max={8}
                                          name={`ballonTags.${index}.order`}
                                          id={`ballonTags.${index}.order`}
                                        />
                                        <FormErrorMessage
                                          name={`ballonTags.${index}.order`}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                  <Col md="5">
                                    <FormGroup>
                                      <Label
                                        htmlFor={`ballonTags.${index}.title`}>
                                        Title
                                      </Label>
                                      <Input
                                        type="text"
                                        name={`ballonTags.${index}.title`}
                                        id={`ballonTags.${index}.title`}
                                      />
                                      <FormErrorMessage
                                        name={`ballonTags.${index}.title`}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ))}
                          </FormGroup>
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3" style={{ zIndex: 10 }}>
                    <FormGroup>
                      <Label htmlFor="buttonTimeline">Button Timeline</Label>
                      <Select
                        name="buttonTimeline"
                        id="buttonTimeline"
                        isMulti={false}
                        defaultValue={BUTTON_TIMELINES[0]}
                        isSearchable
                        options={BUTTON_TIMELINES}
                      />
                      <FormErrorMessage name="buttonTimeline" />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <Collapse isOpen={!!values.buttonTimeline}>
                      <FormGroup>
                        <Label htmlFor="buttonTimelineTitle">Title</Label>
                        <Input
                          type="text"
                          name="buttonTimelineTitle"
                          id="buttonTimelineTitle"
                        />
                        <FormErrorMessage name="buttonTimelineTitle" />
                      </FormGroup>
                    </Collapse>
                  </Col>
                  <Col md="4">
                    <Collapse isOpen={!!values.buttonTimeline}>
                      <FormGroup>
                        <Label htmlFor="buttonTimelineImage">Image</Label>
                        <FileUpload
                          name="buttonTimelineImage"
                          accept={{
                            'image/*': ['.png', '.jpeg', '.jpg'],
                          }}
                          maxFiles={1}
                          uploadMessage="Drop image here or click to upload."
                          handleFiles={handleFiles}
                          handleRemoveFiles={handleRemoveFiles}
                          showPreview
                          existingFile={
                            buttonTimelineImage ||
                            articleContent?.buttonTimelineImage
                          }
                        />
                      </FormGroup>
                    </Collapse>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="content">Content</Label>
                      <HTMLEditor
                        sunEditorRef={sunEditorRef}
                        lang="en"
                        initContents={articleContent?.content || ''}
                        buttons={[['image'], ['table']]}
                        onChange={handleContentChange}
                        imageRemotePath={`${STORAGE_BASE_PATH}/${article.id}/contentImages`}
                        height="350px"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <SubmitButton
                    disabled={!isValid || loading || !canEdit}
                    create={create}
                  />
                </div>
              </>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

export default FormArticleContentContent;
