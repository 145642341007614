import React from 'react';
import {
  ViewButton,
  EditButton,
  TableRowButton,
} from '../Common/DataTable/tableActionButtons';
import { generateFlags } from '../Common/DataTable/tableHelpers';
import { cdnUrl } from '../../helpers/file';

const createTableColumns = (
  openViewModal,
  openEditModal,
  openLangModal,
  openPushNotificationModal,
  isTranslator,
) => [
  {
    title: 'Cover',
    name: 'coverImage',
    width: 140,
    formatterComponent: ({ row, value }) => (
      <a href={cdnUrl(value)} target="_blank" rel="noreferrer">
        <img
          src={cdnUrl(value)}
          alt={row.name}
          style={{ maxWidth: '120px', maxHeight: '60px' }}
        />
      </a>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
  {
    title: 'Name',
    name: 'name',
    wordWrapEnabled: true,
  },
  {
    title: 'Categories',
    name: 'categories',
    width: 200,
    wordWrapEnabled: true,
    formatterComponent: ({ row, value }) =>
      Array.isArray(value) && value.length ? value.join(', ') : '',
  },
  {
    title: 'Time Tags',
    name: 'timeTags',
    width: 200,
    wordWrapEnabled: true,
    formatterComponent: ({ row, value }) =>
      Array.isArray(value) && value.length ? value.join(', ') : '',
  },
  {
    title: 'Languages',
    name: 'langs',
    width: 140,
    align: 'center',
    wordWrapEnabled: true,
    formatterComponent: ({ row }) => generateFlags(row, openLangModal),
    filter: 'disabled',
    sorting: 'disabled',
  },
  {
    title: 'Action',
    name: 'action',
    width: 140,
    align: 'center',
    formatterComponent: ({ row }) => (
      <>
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          {!isTranslator && <EditButton row={row} onPress={openEditModal} />}
          {!isTranslator && openPushNotificationModal && row.langs?.length && (
            <TableRowButton
              name="pushNotification"
              row={row}
              icon="bx bx-bell-plus"
              label="Create Push Notification"
              onPress={openPushNotificationModal}
            />
          )}
        </ul>
      </>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
